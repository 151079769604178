import React from 'react';

import ForgotPasswordMobile from 'components/ForgotPassword/ForgotPasswordMobile';

export default function ForgotPasswordPage() {
  return (
    <div>
      <ForgotPasswordMobile />
    </div>
  );
}
