export const PLATFORMS = [
  'Facebook',
  'Messenger',
  'Instagram',
  'Twitter',
  'Linkedin',
  'Youtube',
];

export const PLACEHOLDER = 1;
