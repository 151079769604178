export const SHORTEN_URL = 'SHORTEN_URL';
export const URL_ERROR = 'URL_ERROR';
export const SHOW_URL_MODAL = 'SHOW_URL_MODAL';
export const SHOW_SIGN_UP_MODAL = 'SHOW_SIGN_UP_MODAL';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_WITH_GOOGLE_SUCCESS = 'LOGIN_WITH_GOOGLE_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const EDIT_SLUG = 'EDIT_SLUG';
export const SHORTEN_URL_WITH_SLUG = 'SHORTEN_URL_WITH_SLUG';
export const SLUG_ALREADY_EXISTS = 'SLUG_ALREADY_EXISTS';
export const SHOW_LOG_IN_MODAL = 'SHOW_LOG_IN_MODAL';
export const SHOW_FORGOT_PASSWORD_MODAL = 'SHOW_FORGOT_PASSWORD_MODAL';
export const TOGGLE_SNACKBAR_OPEN = 'TOGGLE_SNACKBAR_OPEN ';
export const TOGGLE_SNACKBAR_CLOSE = 'TOGGLE_SNACKBAR_CLOSE';
export const TOGGLE_CHANGE_PASSWORD_SNACKBAR_OPEN = 'TOGGLE_CHANGE_PASSWORD_SNACKBAR_OPEN ';
export const TOGGLE_CHANGE_PASSWORD_SNACKBAR_CLOSE = 'TOGGLE_CHANGE_PASWORD_SNACKBAR_CLOSE';
export const SHOW_DELETE_URL_MODAL = 'SHOW_DELETE_URL_MODAL';
export const SHOW_COPY_SUCCESS_MODAL = 'SHOW_COPY_SUCCESS_MODAL';
export const SHOW_EDIT_URL_MODAL = 'SHOW_EDIT_URL_MODAL';
export const SHOW_GOOGLE_LOADING_ANIMATION = 'SHOW_GOOGLE_LOADING_ANIMATION';
export const EDIT_EXPIRE_TIME = 'EDIT_EXPIRE_TIME';
export const UPDATE_URL_LISTS = 'UPDATE_URL_LISTS';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const USER_NOT_FOUND = 'USER_NOT_FOUND';
export const INVALID_SLUG = 'INVALID_SLUG';
export const SLUG_TAKEN = 'SLUG_TAKEN';
export const SLUG_EDIT_INVALID = 'SLUG_EDIT_INVALID';
export const SHOW_INFO_BAR = 'SHOW_INFO_BAR';
export const CLOSE_INFO_BAR = 'CLOSE_INFO_BAR';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export const WRONG_PASSWORD = 'WRONG_PASSWORD';
export const ACCOUNT_NOT_EXISTS = 'ACCOUNT_NOT_EXISTS';
export const WEAK_PASSWORD = 'WEAK_PASSWORD';
export const EMAIL_ALREADY_IN_USE = 'EMAIL_ALREADY_IN_USE';
export const NEW_EMAIL_TAKEN = 'NEW_EMAIL_TAKEN';
export const TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS';
export const EXPIRED_ACTION_CODE = 'EXPIRED_ACTION_CODE';
export const INVALID_ACTION_CODE = 'INVALID_ACTION_CODE';
export const USER_DISABLED = 'USER_DISABLED';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
export const CHANGE_PASSWORD_LOADING = 'CHANGE_PASWORD_LOADING';

export const UPDATE_URL_DETAIL = 'UPDATE_URL_DETAIL';
export const UPLOAD_IMG = 'UPLOAD_IMG';
export const CHANGE_SORT_OPTION = 'CHANGE_SORT_OPTION';
